.main-menu.section {
    flex: 1;
    display: flex;
    justify-content: center;
}

.main-menu .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.main-menu .logo {
    width: 30%;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

@media (max-width: 768px) {

    /* Your CSS rules for mobile devices */
    .main-menu .logo {
        width: 50%;
    }
}