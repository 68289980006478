.top {
    align-items: center;
    background: linear-gradient(to right, #028b62, #008058, #016d4d);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    display: flex;
    color: white;
    height: 70px;
    justify-content: space-between;
    padding: 0 20px;
}

.top .team-stats {
    font-size: 1.3em;
}

.top .column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 20px;
}

.top .column.row {
    flex-direction: row;
}

@media (max-width: 768px) {
    .top {
         padding: 0px;
    }

    .top .team-stats {
        font-size: 0.7em;
        padding: 0 10px;
    }

    .top .column {
        padding: 0 10px;
    }
}