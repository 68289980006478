.sp-button {
    background-color: #2ab6ae;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    color: #fff;
    border: none;
    font-size: xx-large;
    font-weight: bold;
    padding: 20px 40px 15px;
    border-radius: 10px;
    cursor: pointer;
}


@media (max-width: 768px) {
    .sp-button {
        font-size: x-large;
    }
}