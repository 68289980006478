.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 20000;
}

.modal-content {
    background: white;
    color: black;
    display: flex;
    flex-direction: column;
    padding: 30px 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 500px; /* Ensures the modal isn’t too wide on larger screens */
    z-index: 20001;
}

.modal-content.center {
    align-items: center;
}

.modal-close-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modal-title {
    font-size: 2em;
}

.modal-close-button {
    cursor: pointer;
    margin-top: -10px;
}

@media (max-width: 768px) {
    .modal-content {
        width: 80%; /* Slightly wider on smaller screens for better visibility */
    }
}
