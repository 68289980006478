.table {
    flex: 1;
    position: relative;
}

.board {
    align-items: stretch;
    display: flex;
    flex-grow: 4;
    justify-content: center;
    padding: 10px;
}