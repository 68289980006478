.menu-icon {
    display: flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
}

.menu-icon:hover {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    cursor: pointer;
}