.player-stats {
    display: flex;
    font-size: 1.1em;
}

.player-stats .hand {
    font-size: 0.7em;
}

.player-stats .count {
    display: flex;
    align-items: center;
    padding: 0 15px;
    font-size: 2em;
}

.player-stats .team {
    text-transform: uppercase;
    font-size: 0.7em;
}

.current-turn {
    font-size: 0.7em;
    text-transform: uppercase;
    padding: 1px 10px;
    background: #b37feb;
    border-radius: 10px;
    text-align: center;
}

.passed {
    font-size: 0.7em;
    text-transform: uppercase;
    padding: 1px 10px;
    background: #ff7a45;
    border-radius: 10px;
    text-align: center;
}

.detail-subtitle {
    display: flex;
    align-items: center;
}

@media (max-width: 768px) {
    .player-stats {
        font-size: 0.9em;
    }

    .player-stats .count {
        font-size: 1em;
        padding: 0px 10px;
    }
}