body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  background: linear-gradient(to right, #03BB85, #00A974, #008B62);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.playful-font {
  font-family: "Luckiest Guy";
  font-weight: 400;
  font-style: normal;
}

.boogaloo-regular {
  font-family: "Boogaloo", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.font-smaller {
  font-size: smaller;
}

.font-large {
  font-size: large;
}

.font-xlarge {
  font-size: 4em;
}

.font-xxlarge {
  font-size: 5em;
}

.flex-2 {
  flex: 2
}

.hand {
  margin-right: 5px;
}

.error-page {
  display: flex;
  flex-direction: column;
  padding: 100px 40px;
  /* justify-content: center; */
  align-items: center;
}

.error-page p {
  font-size: 1.5em;
}

.error-page .logo {
  width: 20%;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

@media (max-width: 768px) {

  /* Your CSS rules for mobile devices */
  .error-page .main-menu .logo {
    width: 50%;
  }
}