.controls {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: 8px;
}

.deck {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.deck-status {
    height: 20px;
    margin-bottom: 5px;
    display: flex;
}

.deck-status .current-turn {
    font-size: 0.9em;
    padding: 5px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.deck-status .passed {
    font-size: 0.9em;
    padding: 5px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.deck-set {
    display: flex;
    justify-content: center;
}