.card {
    padding: 20px;
    margin-bottom: 20px;
    border: 4px solid #d9d9d9;
    border-radius: 10px;
}

.card:hover {
    border-color: #03BB85;
    cursor: pointer;
    background: #fafafa;
}

.card.selected {
    border-color: #03BB85;
    /* border-color: #008B62;; */
    background: #fafafa;
}