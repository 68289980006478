/* Styling for the domino tile */
.domino {
    width: 48px;
    height: 84px;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 6px rgba(214, 214, 214);
    padding: 8px 4px;
    display: flex;
    flex-direction: column;
    border: 0.5px solid rgba(214, 214, 214);
}

.table>.domino {
    position: absolute;
}

.domino.small {
    border-radius: 6px;
    width: 28px;
    height: 48px;
    padding: 4px 2px;
    box-shadow: 0 4px rgba(214, 214, 214);
}

.domino.can-play .dot {
    background: #016d4d;
}

.domino.can-play .dot.white {
    background: #fff;
}

.domino .row {
    height: 8px;
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.domino.small .row {
    height: 4px;
}

.domino .face {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.domino.horizontal {
    width: 84px;
    height: 48px;
    flex-direction: row;
    padding: 4px 8px;
}

.domino.horizontal.small {
    width: 48px;
    height: 28px;
    padding: 2px 4px;
}

.domino.horizontal .face {
    flex-direction: row;
}

.domino.horizontal .row {
    flex-direction: column;
    height: 100%;
    width: 8px;
}

.domino.horizontal.small .row {
    width: 4px;
}

/* Dot styling to make them look cute */
.dot {
    width: 9px;
    height: 9px;
    background-color: #333;
    border-radius: 50%;
}

.domino.small .dot {
    width: 5px;
    height: 5px;
}

.dot.white {
    background-color: #fff;
}

.line {
    background: #333;
    border-radius: 24px;
    height: 4px;
    margin: 4px;
}

.domino.small .line {
    height: 2px;
    margin: 2px;
}

.domino.horizontal .line {
    width: 4px;
    height: 80%;
}

.domino.horizontal.small .line {
    width: 2px;
}

.controls .domino:hover {
    background: #ede6e6;
    cursor: pointer;
}

.controls .domino:hover .dot.white {
    background: #ede6e6;
}

@media (min-width: 768px) {
    .deck .domino {
        width: 70px;
        height: 140px;
    }

    .deck .dot {
        width: 14px;
        height: 14px;
    }
}


.play-selection-content .option .domino {
    animation: wiggle 2s ease infinite;
}

/* Animation to add a fun, wiggling effect */
@keyframes wiggle {

    0%,
    100% {
        transform: rotate(-5deg);
    }

    50% {
        transform: rotate(5deg);
    }
}