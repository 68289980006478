.play-selection-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    z-index: 10000;
}

.play-selection-content {
    background: white;
    color: black;
    padding: 30px 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 80%;
    z-index: 10001;
    margin-bottom: 100px;
}

.play-selection-content .title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    font-size: 2em;
}

.play-selection-content .helper {
    font-size: 0.7em;
}

.play-selection-content .row {
    display: flex;
    justify-content: space-around;
}

.play-selection-content .option {
    background: #0e9a70;
    padding: 20px 40px;
    border: 1px solid;
    border-radius: 5px;
}